/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

function reverseObject(obj) {
  new_obj= {}
  rev_obj = Object.keys(obj).reverse();
  rev_obj.forEach(function(i) { 
    new_obj[i] = obj[i];
  })
  return new_obj;
}
